<template>
  <div class="app-container none-border">
    <div class="top_btns">
      <div class="left_btns">
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:21px 0px 0px 16px;height:117px;width:430px" class="styleForm">
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <el-date-picker size="small" v-model="listQuery.period" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYYMM"></el-date-picker>
            </el-form-item>
            <el-form-item label="会计期间 ：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.beginTime" style="margin-left: 0"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime"></qzf-period>
            </el-form-item>
          </el-form>
        </search>
        <span style="cursor: pointer;margin-left: 15px;font-size: 14px;" @click="changeMode">
          切换样式：
          <i class="icon iconfont icon-caidan" v-if="hisModeType"></i>
          <i class="icon iconfont icon-liebiaoxingshi"  v-else></i> 
        </span>
      </div>
      <div style="float:right;margin-left:10px;">
        <!-- <qzf-import-back @success="getList()" from="历史凭证"></qzf-import-back> -->
      </div>
      <div class="right_btn">
        <el-button type="primary" size="small" @click="voucherAdd" plain>添加</el-button>
        <el-button type="success" size="small" @click="dayin">打印</el-button>
        <el-button type="primary" size="small" @click="daochuVoucher">导出</el-button>
        <el-button type="primary" size="small" @click="settleAccount">结转损益</el-button>
        <el-button type="primary" size="small" @click="handlePaixu">一键排序</el-button>
      </div>
    </div>
    <div v-if="hisModeType">
      <el-table :data="list" style="width: 100%"  v-loading="loading1" border :height="contentStyleObj" @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column align="center" prop="voucherNo" label="凭证字号" width="90">
          <template #default="scope">
            <i style="cursor:pointer;color:#39b0d2" class="iconfont icon-pingzheng"  @click="handleUpdate(scope.row)"></i>
            <qzf-button  @success="paixu(scope.row)" style="cursor:pointer;color:#39b0d2;position:relative;top:-3px;font-size:13px;margin-left:3px;padding: 0;" type="text"> {{ scope.row.voucherNo }}</qzf-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="voucherDate" label="日期" width="150">
          <template #default="scope">
            <!-- <span v-if="scope.row.voucherDate">{{ scope.row.voucherDate }}</span> -->
            <span v-if="scope.row.voucherDate.length > 20">{{ $parseTime(scope.row.voucherDate, "{y}-{m}-{d}")}}</span>
            <span v-else>{{ scope.row.voucherDate}}</span>


          </template>
        </el-table-column>
        <el-table-column align="center" prop="period" label="账期" width="150">
          <template #default="scope">
            <span>{{ scope.row.period}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="voucherNo" label="凭证字号" width="60">
          <template #default="scope">
            <div class="item_icon">
              <span>{{ scope.row.voucherNo }}</span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column align="center" label="摘要" width="200">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.summary" :key="index" style="white-space: nowrap;">{{item}}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="科目编码名称" width="250">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.subject_names" :key="index" style="white-space: nowrap;">{{item}}<span v-if="scope.row.fzhs_names[index]">--辅助核算：{{ scope.row.fzhs_names[index] }}</span></p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="借方">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.in_amount" :key="index" style="white-space: nowrap;">
              {{item !== '0.00'?this.$comdify(item):''}}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="贷方">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.out_amount" :key="index" style="white-space: nowrap;">
              {{item !== '0.00'?this.$comdify(item):''}}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="billCount" label="附件张数" width="60"></el-table-column>
        
        <el-table-column align="center" prop="address" label="操作" width="180">
          <template #default="scope">
            <qzf-button @success="handleUpdate(scope.row)" size="small" type="text">编辑</qzf-button>
            <qzf-button @success="handleDup(scope.row)" size="small" type="text">复制</qzf-button>
            <qzf-button size="small" @success="del(scope.row)" type="text">删除</qzf-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="main_list" v-else>
      <div class="main_left"></div>
      <div class="main_right">
        <div class="top_title">
          <div class="left_box" @click="allSelect">
            <input type="checkbox" :checked="isAllSelected" :style="{marginLeft:0,marginTop:'10px'}"/>
          </div>
          <div class="top-nav right_box">
            <el-row>
            <el-col :span="9">
              <div class="box-content" style="border-left:none;">摘要</div>
            </el-col>
            <el-col :span="9">
              <div class="box-content">科目</div>
            </el-col>
            <el-col :span="3">
              <div class="box-content">借方金额</div>
            </el-col>
            <el-col :span="3">
              <div class="box-content">贷方金额</div>
            </el-col>
            </el-row>
          </div>
       </div>
       <div v-loading="loading1">
       <el-scrollbar :style="{height:contentStyleObj1}">
          <el-empty :image-size="150" v-if="this.list.length == 0"></el-empty>
          <div class="content_center"  v-for="(item,index) in list" :key="index">
            <div class="left_box fl" @click="singleSelect(item,index)">
              <input type="checkbox" :checked="item.checked" :style="{marginLeft:0,marginTop:'10px'}"/>
            </div>
            <!-- <div class="pic">
              <img src="../../assets/wsh.png" alt="" style="width:85px" v-if="item.checkStatus == 0">
              <img src="../../assets/shtg.png" alt="" style="width:85px" v-else>
              </div> -->
            <div class="content-nav right_box fl">
             
              <el-row class="content-top">
                <el-col :span="24" class="box-content clearfix">
                  <ul class="top_font clearfix">
                    <li style="width:80px">
                      <span style="color:#39b0d2">{{ item.voucherDate}}</span>
                    </li>
                    <li style="width:80px">
                      <i style="cursor:pointer;color:#39b0d2" class="iconfont icon-pingzheng"  @click="handleUpdate(item)"></i>
                      <qzf-button  @success="paixu(item)" style="cursor:pointer;color:#39b0d2;position:relative;top:-3px;font-size:13px;margin-left:3px;padding: 0;" type="text"> {{ item.voucherNo }}</qzf-button>
                    </li>
                    <li style="width:100px">账期：
                      <span style="color:#39b0d2">{{ item.period}}</span>
                    </li>    
                    <li>附单据：
                      <span style="color:#39b0d2">{{ item.billCount}}张</span>
                    </li>    
                  </ul>
                  <div class="top_right_btns">
                  
                    <!-- 可能用不要删 -->
                    <el-tooltip content="编辑" placement="top"  effect="dark">
                      <qzf-button @success="handleUpdate(item)" size="small" type="text" icon="Edit"></qzf-button>
                    </el-tooltip>
                    <el-tooltip content="复制" placement="top"  effect="dark">
                      <qzf-button @success="handleDup(item)" size="small" type="text" icon="DocumentCopy" style="margin:0"></qzf-button>
                    </el-tooltip>
                    <el-tooltip content="删除" placement="top"  effect="dark">
                      <qzf-button  size="small" @success="del(item)" type="text" icon="Delete" style="margin: 0;"></qzf-button>
                    </el-tooltip>
                  </div>
                </el-col>
              </el-row>
              <div class="bottom-nav">
                <el-row>
                <el-col :span="9">
                  <p v-for="(itemS,index) in item.summary" :key="index" class="text_none">{{itemS}}</p>
                </el-col>
                <el-col :span="9"> 
                  <p v-for="(itemN,index) in item.subject_names" :key="index" class="text_none">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      placement="top-start"
                      raw-content
                      >
                        <template #content>
                          <div style="max-width: 500px;">
                            {{  item.fzhs_names[index]?itemN + '--辅助核算：' + item.fzhs_names[index]:itemN }}
                          </div> 
                        </template>
                       <span >{{  item.fzhs_names[index]?itemN + '--辅助核算：' + item.fzhs_names[index]:itemN }}</span>
                    </el-tooltip>
                  </p>
                </el-col>
                <el-col :span="3">
                <p v-for="(itemI,index) in item.in_amount" :key="index">
                  {{itemI !== '0.00'?this.$comdify(itemI):''}}
                </p>
                </el-col>
                <el-col :span="3">
                <p v-for="(itemO,index) in item.out_amount" :key="index">
                {{itemO !== '0.00'?this.$comdify(itemO):''}}
               </p>
                </el-col>
                </el-row>
              </div>
              <div class="bottom-nav">
                <el-row>
                <el-col :span="18">
                  <div style="font-weight: 600;" v-if="item.inSumAmount != 0">合计：{{ saveBig(item.inSumAmount) }}</div>
                </el-col>
                <el-col :span="3">
                <div>{{this.$comdify(item.inSumAmount)}}</div>
                </el-col>
                <el-col :span="3">
                <div> {{this.$comdify(item.outSumAmount)}}</div> 
                </el-col>
                </el-row>
              </div>
              
            </div>
          </div>
        </el-scrollbar>
      </div>
      </div>
    </div>
    <div class="bottom_style">
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>

    <printVouchPz ref="printVouchPz" :listIds="allselect" name="book_voucher" :listQuery1="this.listQuery"></printVouchPz>
    <exportFile ref="exportFile" name="book_voucher" :listQuery1="this.listQuery"></exportFile>
    <duplicate ref="duplicate" @success="getList" voucherType="oldVoucher"></duplicate>

  </div>
</template>

<script>
import { hisVoucherList,delHisVoucher ,hisSyjz,editHisVoucherNo ,hisVoucherSort } from "@/api/old"
import printVouchPz from "./components/printVouchPz.vue"
import exportFile from "./components/exportFile.vue"
import duplicate from "../bookkeeps/components/duplicate.vue"


export default {
  name: "voucherhis",
  components:{
    printVouchPz,
    exportFile,
    duplicate,

  },
  data() {
    return {
      listQuery:{
        limit:50,
        page:1,
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
      },
      list: [],
      total: 0,
      select: [],
      allselect:[],
      period:'',
      beginTime:'',
      endTime:'',
      changeTime:'',
      hisModeType:true,
      loading1:false,
      contentStyleObj1:{}
    }
  },
  created() {
    this.contentStyleObj = this.$getHeight(210)
    this.contentStyleObj1 = this.$getHeight(256)
    this.getList();
    if(localStorage.getItem("hisModeType") != undefined){
      this.hisModeType = localStorage.getItem("hisModeType") == 'true'
    }
  },
  computed: {
    // 是否全选
    isAllSelected() {
      if(this.list.length == 0){
        return false
      }
      return this.list.every(el => {
        return el.checked;
      });
    },
  },
  methods: {
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        limit:originLimit,
        page:1,
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
      }
      this.getList()
    },
    getList(){
      this.loading1 = true
      hisVoucherList(this.listQuery).then(res=>{
        this.loading1 = false
        if(res.data.msg == "success"){
          let list = res.data.data.list;
          list.map(item => {
            item.item_type = item.item_type.split("#|#");
            item.summary = item.summary.split("#|#");
            item.subject_code = item.subject_code.split("#|#");
            item.subject_names = item.subject_names.split("#|#");
            item.fzhs_names = item.fzhs_names.split("#|#");
            // item.subject_codes = item.subject_code.split("#|#");
            item.in_amount = item.in_amount.split("#|#");
            item.out_amount = item.out_amount.split("#|#");
            item.item_remark = item.item_remark.split("#|#");
            item.checked = false
            // item.fromType = item.fromType;
            // item.fromType = item.fromType;
          });
          this.list = list;
          this.select = [];
          this.allselect = [];
          // this.list = res.data.data.list
        }
      })
    },
    changeQujianTime(){
      this.beginTime = this.changeTime[0]
      this.endTime = this.changeTime[1]
    },
    //单选
    singleSelect(row,index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.list = this.list.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.select = this.list.filter(v=>{return v.checked})//所有
      let arr = []
      this.select.map(v=>{
        arr.push(v.id)
      })
      this.allselect = arr;//id push
    },
    handleSelectionChange(e){
      let arr = [];
      e.map(v => {
        arr.push(v.id);
      });
      this.allselect = arr;
    },
    // 编辑
    handleUpdate(e) {
      this.$store.dispatch('commons/setParam', {historyVoucherId: e.id,hisVoucherPeriod: e.period})
      this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
      this.$router.push({
        path: "/bookkeeps/components/historyVoucher",
        name: "historyVoucher",
        query:{type:'edit'}
      });
    },
    //新增
    voucherAdd() {
      this.$store.dispatch('commons/setParam', {hisVoucherPeriod: this.listQuery.period,historyVoucherId: 0})
      this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
      this.$router.push({
        path: "/bookkeeps/components/historyVoucher",
        name: "historyVoucher",
        query:{type:'add'}
      });
    },
    dayin() {
      if(this.allselect.length == 0){
        this.$message.error('请至少选择一张要打印的凭证');
        return
      }
      this.$refs.printVouchPz.init()
    },
    daochuVoucher(){
      this.$refs.exportFile.init()
    },
    del(e) {
      this.$confirm("确认删除该凭证", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'

      }).then(() => {
        delHisVoucher({ id: e.id}).then(res => {
          if(res.data.msg == 'success'){
            this.$message({
              type: "success",
              message: "删除成功"
            });
            this.getList();
          }
        });
      });
    },
    settleAccount(){
      // this.$prompt('请输入凭证号(例： 记-1)', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   inputErrorMessage: '格式不正确'
      // }).then(({ value }) => {
      hisSyjz({}).then(res=>{
        if(res.data.msg == "success"){
          this.getList();
          this.$qzfMessage("成功")
        }
      })
      // })
    },
    paixu(row){
      if(this.$checkSettleStatus()) return
      this.$prompt('请输入凭证号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        editHisVoucherNo({id:row.id,voucherNo:value}).then(res=>{
          if(res.data.msg == "success"){
            this.getList();
            this.$qzfMessage("修改成功")
          }
        })
      })
    },
    //一键排序
    handlePaixu(){
      if(this.$checkSettleStatus()) return
      hisVoucherSort({}).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          this.getList()
        }
      })
    },
    //复制
    handleDup(row) {
      //console.log(row.id);
      this.$refs.duplicate.init(row.id)
    },
    changeMode(){
      this.hisModeType = !this.hisModeType;
      localStorage.setItem("hisModeType",this.hisModeType);
      this.getList()
    },
    saveBig(money) {
      let statusFu = false
      if(money < 0){
        money = -money
        statusFu = true
      }
      //汉字的数字
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      var cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      var cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      var cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      var cnInteger = "整";
      //整型完以后的单位
      var cnIntLast = "圆";
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = "";
      //分离金额后用的数组，预定义
      var parts;
      if (money == "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == "") {
        chineseStr += cnInteger;
      }

      if(statusFu){
        chineseStr = "负" + chineseStr
      }

      return chineseStr;
    },
  },

}
</script>

<style scoped lang="scss">
 
.none-border p {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
 // white-space: nowrap;
}

.none-border p:first-child {
  border-top: none;
}
.top_btns {
  .left_btns{
    float: left;
    width: 40%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .right_btn {
    float: right;
    width: 55%;
    text-align: right;
  }
}
</style>
<style>
.none-border .cell {
  padding: 0;
}
.none-border .el-table__row td {
  padding: 0 !important;
}

.none-border .cell {
  padding: 0;
  text-align: center;
}
</style>
<style lang="scss" scoped>
.left_box {
  float: left;
  width: 2%;
  input {
    width: 80%;
    height: 16px;
  }
}
.right_btns{
  width: 3%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.top_title {
  display: flex;
  width: 100%;
}
.right_box {
  width: 99%;
}
.top-nav {
  height: 36px;
  background: #eee;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
  position: relative;
  border-bottom: 1px solid #c2c2c2;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    height: 34px;
  }
}
.bottom-nav{
 // height: 36px;
  font-size: 14px;
  position: relative;
  color: #333;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #c2c2c2;
    line-height: 34px;
    font-size: 14px;
    height: 34px;
  }
}
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.content_center {
  display: flex;
  position: relative;
}
.content-nav {
  line-height: 36px;
  border-bottom: none;
  
  .content-top {
    background: #f6f6f6;
    .box-content {
      box-sizing: border-box;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
    }
    .box-content:first-child(1) {
      border-left: none;
    }
  }
  .for-content {
    .box-content {
      box-sizing: border-box;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
      padding: 0 10px;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
  }
  .bottom-content {
    .box-content {
      box-sizing: border-box;
      text-align: center;
      line-height: 36px;
       height: 36px;
      font-size: 14px;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
  }
}
.top_font {
  float: left;
  width: 83%;
  margin: 0 auto;
  li {
    font-size: 14px;
    color: #333;
    float: left;
   // width: 18%;
    text-align: left;
    padding-left: 15px;
    margin-right: 10px;
  }
}
.top_right_btns {
  float: right;
  margin-right:10px;
}
.top_right_btn2 {
  float: right;
  margin-right: 40px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 18px;
    line-height: 24px;
  }
}

.el-col-9 {
  border-right: 1px solid #cfc2c2;
  div{
    padding-left: 10px;
    box-sizing: border-box;
  }
  
}
.el-col-3 {
  border-right: 1px solid #cfc2c2;
  text-align: right;
  padding-right: 10px;
}
.el-col-18 {
  border-right: 1px solid #cfc2c2;
  padding-left: 10px;
}

.content_center:last-child{
    .content-nav{
      border-bottom: 1px solid #c2c2c2;
    }
}
.el-row{
  display: flex;
  border: 1px solid #c2c2c2;
  border-bottom: none;
}
.bottom-nav{
  .el-col-3:last-child{
    border-right: none;
  }
}
.top_right_btns :deep(.el-icon){
  color: var(--themeColor,#17a2b8);
  font-size: 18px;
}
.text_none{
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pic{
  position: absolute;
  right: 100px;
  z-index: 3;
  top: 25px;
  opacity: 0.3;
}
.iconfont{
  font-size: 18px;
}
</style>